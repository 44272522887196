<!--
 * @Author: lzh
 * @Date: 2022-07-06 17:08:21
 * @LastEditors: lbh
 * @LastEditTime: 2023-07-13 16:42:43
 * @Description: file content
-->
<template>
  <div class="offer-data-box px-offer-data-box">
    <h1
      class="title"
      @click="clickItem('title')"
      :class="`edit_${Xindex}_title`"
      v-html="$util.rex.getHtml(configs[`${nowL}title`] || configs.title)"
    ></h1>
    <h2
      class="desc"
      @click="clickItem('desc')"
      :class="`edit_${Xindex}_desc`"
      v-html="$util.rex.getHtml(configs[`${nowL}desc`] || configs.desc)"
    ></h2>
    <div class="data-block">
      <div class="_">
        <div
          v-for="(item_,index_) in configs.childs"
          :key="index_"
          class="block1"
          @click="clickItem('items', index_)"
          :class="`edit_${Xindex}_items_${index_}`"
        >
          <div class="first">
            <div
              class="title"
              v-for="(item, index) in item_.dataArr"
              :key="index"
              v-html="$util.rex.getHtml(item[`${nowL}title`] || item.title)"
            ></div>
            <div class="price-year-box">
              <div
                class="price"
                v-html="$util.rex.getHtml(item_[`${nowL}price`] || item_.price)"
              ></div>
              <div
                class="year"
                v-html="$util.rex.getHtml(item_[`${nowL}year`] || item_.year)"
              ></div>
            </div>
            <div class="xian xian_"></div>
            <div
              class="system-box"
              v-for="(item, index) in item_.dataArr"
              :key="`${index}a`"
              :style="index == 0 ? 'margin-top:0;' : ''"
            >
              <div>
                <div
                  class="title"
                  v-html="$util.rex.getHtml(item[`${nowL}subheading`] || item.subheading)"
                ></div>
                <div
                  class="item"
                  v-for="(it_, in_) in item.datas"
                  :key="in_"
                >
                  <div
                    class="text"
                    v-html="$util.rex.getHtml(in_ + 1 + '.' + (it_[`${nowL}title`] || it_.title))"
                  ></div>
                </div>
              </div>
              <img
                class="icon"
                src="../../assets/do-img/select.png"
              />
            </div>
            <div class="xian"></div>
            <div
              class="checkData"
              v-for="(item, index) in item_.checkData"
              :key="`${index}b`"
            >
              <div
                class="title"
                v-html="$util.rex.getHtml(item[`${nowL}title`] || item.title)"
              ></div>
              <img
                class="icon"
                src="../../assets/do-img/select.png"
              />
            </div>
          </div>
          <div class="btn-box">
            <selfButton
              v-if="item_.btn1"
              :go="item_.btn1.go"
            >
              {{item_.btn1[`${nowL}title`] || item_.btn1.title}}
            </selfButton>
            <selfButton
              v-if="item_.btn2"
              theme="none"
              :go="item_.btn2.go"
            >
              {{item_.btn2[`${nowL}title`] || item_.btn2.title}}
            </selfButton>
          </div>
        </div>
      </div>
    </div>
    <div
      class="attention"
      v-html="$util.rex.getHtml(configs[`${nowL}attention`] || configs.attention)"
    ></div>

  </div>
</template>

<script>
export default {
  name: 'self-offer-data',
  data () {
    return {
      isEdit: '',
      showOffer: false,
      nowL: ''
    };
  },
  props: {
    configs: {
      default () {
        return {
          title: '',
          desc: '',
          attention: '',
          icon: '',
          childs: []
        };
      },
    },
    Xindex: {
      default () {
        return {};
      },
    },
    lang: {
      default () {
        return '';
      }
    }
  },
  watch: {
    lang () {
      this.initLang();
    }
  },
  created () {
    this.initLang();
    this.isEdit = this.$store.getters.getIsEdit;
  },
  methods: {
    initLang () {
      let now_language = this.$storage.get('now_language');
      if (now_language === 'HK') now_language = '';
      this.nowL = now_language;
    },
    clickItem (e, x) {
      if (this.isEdit) {
        this.$emit('clickItem', e, x);
      }
    },
  },
};
</script>

<style lang="less" scoped>
.offer-data-box {
  padding-top: 70px;
  text-align: center;
  .title {
    font-size: 30px;
    font-family: PingFang SC-Semibold, PingFang SC;
    font-weight: 600;
    color: #000000;
  }
  .desc {
    font-size: 20px;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #999999;
    margin-top: 6px;
  }
  .data-block {
    margin-top: 60px;
    display: flex;
    justify-content: center;
    ._ {
      padding: 0 450px;
      width: 100%;
      display: flex;
      justify-content: space-between;
      .block1 {
        flex: 1;
        border-radius: 10px;
        border: 1px solid #cccccc;
        padding: 40px;
        padding-top: 60px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        margin-right: 90px;
        &:nth-of-type(2) {
          margin: 0;
        }
        .first {
          width: 100%;
        }
        .title {
          font-size: 24px;
          font-family: PingFang SC-Semibold, PingFang SC;
          font-weight: 600;
          color: #333333;
        }
        .price-year-box {
          display: flex;
          align-items: baseline;
          color: var(--themeColor);
          padding-top: 24px;
          justify-content: center;
          .price {
            font-size: 60px;
            font-family: DINPro-Medium, DINPro;
            font-weight: 500;
          }
          .year {
            font-size: 20px;
            padding-left: 6px;
          }
        }
        .system-box {
          width: 100%;
          text-align: left;
          display: flex;
          align-self: flex-start;
          justify-content: space-between;
          margin-top: 20px;
          .title {
            font-size: 24px;
            font-family: PingFang SC-Medium, PingFang SC;
            font-weight: bold;
            color: #333333;
            margin-bottom: 8px;
          }
          .item {
            .text {
              margin-top: 12px;
              font-size: 20px;
              font-family: PingFang SC-Regular, PingFang SC;
              font-weight: 400;
              color: #616161;
            }
          }
          .icon {
            width: 24px;
            height: 24px;
            border-radius: 50%;
          }
        }
        .checkData {
          display: flex;
          justify-content: space-between;
          align-self: flex-start;
          width: 100%;
          margin-bottom: 10px;
          .icon {
            width: 24px;
            height: 24px;
          }
        }
        .btn-box {
          width: 100%;
          margin-top: 25px;
          & /deep/ .self-button-box {
            display: block;
          }
          & :nth-child(2) {
            margin-top: 20px;
          }
        }
        &:hover {
          box-shadow: 3px 3px 40px 1px rgba(190, 28, 66, 0.06),
            -3px -3px 40px 1px rgba(190, 28, 66, 0.1);
          border: 1px solid var(--themeColor);
        }
      }
      .block2 {
        margin-left: 90px;
      }
      .xian {
        width: 100%;
        height: 1px;
        background-color: #ccc;
        margin: 20px 0 24px 0;
      }
      .xian_ {
        margin: 20px 0 42px 0;
      }
    }
  }
  .attention {
    font-size: 20px;
    font-weight: 400;
    color: #333333;
    padding: 34px 0 70px 0;
  }
}

@media screen and (max-width: 1000px) {
  .px-offer-data-box {
    padding: 40px 25px 25px;
    .title {
      font-size: 30px;
      font-family: PingFang SC-Semibold, PingFang SC;
      font-weight: 600;
      color: #000000;
    }
    .desc {
      font-size: 20px;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #999999;
    }
    .data-block {
      ._ {
        padding: 0;
        .block1 {
          border-radius: 6px;
          .first {
            .title {
              font-size: 30px;
              font-family: PingFang SC-Semibold, PingFang SC;
              font-weight: 600;
              color: #333333;
            }
            .price-year-box {
              .price {
                font-size: 60px;
                font-family: DINPro-Medium, DINPro;
                font-weight: 500;
              }
              .year {
                font-size: 18px;
                font-family: DINPro-Medium, DINPro;
                font-weight: 500;
              }
            }
            .system-box {
              .title {
                font-size: 24px;
                font-family: PingFang SC-Medium, PingFang SC;
                font-weight: 500;
                color: #333333;
                margin-bottom: 10px;
              }
              .item {
                .text {
                  font-size: 20px;
                  font-family: PingFang SC-Regular, PingFang SC;
                  font-weight: 400;
                  color: #616161;
                  margin: 0 0 10px;
                }
              }
              .icon {
                width: 24px;
                height: 24px;
              }
            }
            .xian {
              margin: 16px 0;
            }
            .checkData {
              margin-bottom: 16px;
              .title {
                font-size: 24px;
                font-family: PingFang SC-Medium, PingFang SC;
                font-weight: 500;
                color: #333333;
              }
              .icon {
                width: 24px;
                height: 24px;
              }
            }
          }
          .btn-box {
            margin-top: 26px;
            & :nth-child(2) {
              margin-top: 20px;
            }
          }
        }
      }
    }
    .attention {
      font-size: 16px;
      font-weight: 400;
      color: #333333;
      margin-top: 25px;
    }
  }
}

@media screen and (max-width: 500px) {
  .px-offer-data-box {
    padding: 40px 12px 25px;
    .title {
      font-size: 15px;
      font-family: PingFang SC-Semibold, PingFang SC;
      font-weight: 600;
      color: #000000;
    }
    .desc {
      font-size: 12px;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #999999;
      margin-top: 2px;
    }
    .data-block {
      ._ {
        padding: 0;
        .block1 {
          border-radius: 6px;
          .first {
            .title {
              font-size: 15px;
              font-family: PingFang SC-Semibold, PingFang SC;
              font-weight: 600;
              color: #333333;
            }
            .price-year-box {
              padding-top: 8px;
              .price {
                font-size: 25px;
                font-family: DINPro-Medium, DINPro;
                font-weight: 500;
              }
              .year {
                font-size: 14px;
                font-family: DINPro-Medium, DINPro;
                font-weight: 500;
              }
            }
            .xian {
              margin: 6px 0;
            }
            .system-box {
              .title {
                font-size: 11px;
                font-family: PingFang SC-Medium, PingFang SC;
                font-weight: 500;
                color: #333333;
                margin-bottom: 4px;
              }
              .item {
                .text {
                  font-size: 10px;
                  font-family: PingFang SC-Regular, PingFang SC;
                  font-weight: 400;
                  color: #616161;
                  margin: 0 0 4px;
                }
              }
              .icon {
                width: 10px;
                height: 10px;
              }
            }
            .checkData {
              margin-bottom: 8px;
              .title {
                font-size: 10px;
                font-family: PingFang SC-Medium, PingFang SC;
                font-weight: 500;
                color: #333333;
              }
              .icon {
                width: 10px;
                height: 10px;
              }
            }
          }
          .btn-box {
            margin-top: 30px;
            & :nth-child(2) {
              margin-top: 10px;
            }
            & /deep/ .none{
              min-height: 30px;
              height: auto;
              line-height: 19px;
            }
          }
        }
      }
    }
    .attention {
      font-size: 10px;
      font-weight: 400;
      color: #333333;
      margin-top: 25px;
    }
  }
}
</style>
